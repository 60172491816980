.tooltip {
  position: relative;
  display: flex;
  align-items: center;
}

.tooltip__text {
  visibility: hidden;
  width: 120px;
  background-color: var(--color-error-1);
  color: var(--color-light-0);
  text-align: center;
  border-radius: 6px;
  padding: var(--space-3);
  position: absolute;
  z-index: 1;
}

.tooltip__text--top {
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip__text--bottom {
  top: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip__text--left {
  right: 150%;
}

.tooltip__text--right {
  left: 150%;
}

.tooltip__text::after {
  content: '';
  position: absolute;
  border-width: 5px;
  border-style: solid;
}

.tooltip__text--top::after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: var(--color-error-1) transparent transparent transparent;
}

.tooltip__text--bottom::after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: transparent transparent var(--color-error-1) transparent;
}

.tooltip__text--left::after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-color: transparent transparent transparent var(--color-error-1);
}

.tooltip__text--right::after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-color: transparent var(--color-error-1) transparent transparent;
}

.tooltip:hover .tooltip__text {
  visibility: visible;
}
